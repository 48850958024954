package vegasful.admin.loaders

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.components.*

@Composable
fun eventViewer(event: LoaderEvent) {
    box({}) {
        dialogField("ID") {
            Text(event.id)
        }

        event.name?.let {
            dialogField("Name") {
                Text(event.name)
            }
        }

        val venue = event.venue
        if (venue != null) {
            dialogField("Venue") {
                if (venue.id != null) {
                    Text(venue.id)
                } else {
                    val aliases = (venue.aliases ?: listOfNotNull(venue.alias))
                    Text("alias: ${aliases.joinToString()}")
                }
            }
        }

        if (event.description != null) {
            dialogField("Description") {
                raw(event.description)
            }
        }

        if (event.images != null) {
            dialogField("Images") {
                event.images.forEach {
                    Img(src = it.url) {
                        style {
                            maxWidth(150.px)
                            maxHeight(150.px)
                        }
                    }
                }
            }
        }

        if (event.tags != null) {
            dialogField("Tags") {
                Text(event.tags.joinToString(","))
            }
        }

        event.sourceUrl?.let {
            Div { A(href = it) { Text("Source URL") } }
        }

        event.ticketsUrl?.let {
            Div { A(href = it) { Text("Tickets URL") } }
        }

        event.schedule?.forEach { schedule ->
            scheduleEditor(schedule) {
//
            }
        }

        if (listOfNotNull(
                event.startDate,
                event.startTime,
                event.endDate,
                event.endTime,
                event.duration
            ).isNotEmpty()
        ) {
            dialogField("Schedule") {
                detailGrid {
                    event.startDate?.let {
                        detail("Start Date", it)
                    }
                    event.startTime?.let {
                        detail("Start Time", it)
                    }
                    event.duration?.let {
                        detail("Duration", "${it} minutes")
                    }
                    event.endDate?.let {
                        detail("End Date", it)
                    }
                    event.endTime?.let {
                        detail("End Time", it)
                    }
                }
            }
        }
    }
}